import React from 'react';
import messages from '../../BankDetails/messages';
import { useIntl } from 'react-intl';
import CheckIcon from '../../../assets/icons/subscription-check-icon.svg';

const BuySubscriptionInfo = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="subscription-bank-details-container">
      <div className="subscription-bank-details-header flex">
        <span>{formatMessage(messages.subscriptionHeadLabel)}</span>
        <div className="subscription-bank-details-subheader">
          <span className="subscription-bank-details-subheader-price">
            {formatMessage(messages.subscriptionPriceLabel)}
          </span>
        </div>
      </div>
      <div className="subscription-bank-details-content">
        <span>{formatMessage(messages.subscriptionContentLabel)}</span>
        <span className="subscription-bank-details-more-info">
          {formatMessage(messages.subscriptionContentMoreInfo)}
        </span>
      </div>
      <div className="subscription-bank-details-footer flex flex-row md:flex-col">
        <div
          className="subscription-bank-details-footer-content flex"
          style={{ width: '182.24px' }}
        >
          <img src={CheckIcon} />
          <span>{formatMessage(messages.subscriptionCompenstationLabel)}</span>
        </div>
        <div
          className="subscription-bank-details-footer-content flex"
          style={{ width: '147.24px' }}
        >
          <img src={CheckIcon} />
          <span>{formatMessage(messages.subscriptionCommissionLabel)}</span>
        </div>
        <div
          className="subscription-bank-details-footer-content flex"
          style={{ width: '198.24px' }}
        >
          <img src={CheckIcon} />
          <span>{formatMessage(messages.subscriptionCardInfoLabel)}</span>
        </div>
      </div>
    </div>
  );
};

export default BuySubscriptionInfo;
