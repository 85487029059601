import React from 'react';
import { useIntl } from 'react-intl';
import StepTitle from '../../../../components/StepTitle';
import messages from './messages';
import { Listing, ListingRow } from '../../../../components/Listing';
import Icon from '../../../../components/Icon';
import DownArrow from '../../../../assets/icons/download-icon.svg';
import { jsonApiAxios } from '../../../../services/api';
import { useJsonApi } from '../../../../services/GlobalStore/GlobalStore';
import config from '../../../../config.json';

const TermsConditions = ({ claimId }: { claimId: string }) => {
  const { formatMessage } = useIntl();

  const { data } = useJsonApi(
    `claims/${claimId}/dossierItems`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: ['file', 'category', 'passengers.person'].join(),
        filter: {
          'category.id': {
            in: ['11', '101'].join(),
          },
        },
      },
    },
    []
  );

  const downloadFile = (
    fileUrl: string,
    item: { file: { filename: string } }
  ) => {
    const configHeaders = {
      headers: {
        accept: 'application/pdf',
      },
    };
    jsonApiAxios
      .get(fileUrl, { responseType: 'blob', ...configHeaders })
      .then(res => {
        const link = document.createElement('a');
        const fileName = item?.file?.filename;
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: res.headers['content-type'],
          })
        );
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <>
      {data?.length ? (
        <>
          <StepTitle className="mb-30">
            {formatMessage(messages.termsConditionsTitle)} {config?.siteName}
          </StepTitle>
          <Listing>
            {data?.map((item: any) => (
              <ListingRow className="font-bold font-pt-sans" key={item?.id}>
                <div
                  style={{
                    flexBasis: '85%',
                  }}
                >
                  {item?.category?.title}
                </div>
                <div
                  style={{
                    flexBasis: '15%',
                  }}
                >
                  <Icon
                    src={DownArrow}
                    className="h-25 w-25 cursor-pointer"
                    style={{ margin: '0 auto' }}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() =>
                      downloadFile(item?.file?.download_link, item)
                    }
                  />
                </div>
              </ListingRow>
            ))}
          </Listing>
        </>
      ) : null}
    </>
  );
};

export default TermsConditions;
