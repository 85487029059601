import React from 'react';
import bannerPerson from '../../../assets/images/banner-person.png';
import bannerBubble from '../../../assets/images/banner-bubble.png';
import { useIntl } from 'react-intl';
import { useTranslatedNavigate } from '../../../utils/routes';
import translations from '../../../translations/main.json';
import messages from './messages';

const Banner = (props: any) => {
  const { name, showPriceButton, style = {} } = props;
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  const redirectTo = () => {
    translatedNavigate(
      '/' +
        translations['cc4.seo.route.account'] +
        '/' +
        translations['cc4.seo.route.my-premium']
    );
  };

  return (
    <div className="premium-banner" style={style}>
      <div className="banner-content">
        <div className="banner-text-section">
          <h2 className="banner-title">
            {formatMessage(messages.bannerTitle, { name })}
          </h2>
          <h2 className="banner-title-small-screen">
            {formatMessage(messages.bannerMobileTitle)}
          </h2>
          <p className="banner-description">
            {formatMessage(messages.bannerSubTitle)}
          </p>
          {showPriceButton && (
            <button onClick={redirectTo} className="banner-cta">
              {formatMessage(messages.bannerPriceButtonLabel)}
            </button>
          )}
        </div>
        <div className="banner-image-section">
          <img
            src={bannerBubble}
            alt="No commission claims!"
            className="banner-bubble"
          />
          <img src={bannerPerson} alt="" className="banner-person" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
