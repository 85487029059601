import React from 'react';
import CreditsIcon from '../../../assets/icons/credits-icon.svg';
import { isMobile } from 'react-device-detect';
import messages from './messages';
import { useIntl } from 'react-intl';

const CreditBanner = (props: any) => {
  const { data } = props;
  const { formatMessage } = useIntl();

  // Calculate remaining credits based on approved trips
  const approvedCount =
    data?.[0]?.subscriptionTrips?.filter(
      (trip: any) => trip.status === 'Approved'
    )?.length || 0;
  const remainingCredits = Math.max(0, Math.min(3, 3 - approvedCount));

  return (
    <div style={containerStyles}>
      <div className="flex flex-col" style={{ gap: '6px' }}>
        <div className="flex justify-between md:flex-row flex-col">
          <div style={headerStyles}>
            {formatMessage(messages.credidBannerHeader)}
          </div>
          <div
            className="flex items-center md:flex-row flex-col-reverse"
            style={{ gap: isMobile ? '6px' : '24px' }}
          >
            <div className="flex" style={{ gap: '12px' }}>
              {[...Array(remainingCredits)].map((_, index) => (
                <img key={index} src={CreditsIcon} alt="Credit icon" />
              ))}
            </div>
            <span style={headerStyles}>{remainingCredits} of 3</span>
          </div>
        </div>
        <div style={{ textAlign: isMobile ? 'center' : 'left' }}>
          <span style={textStyles}>
            {formatMessage(messages.credidBannerText)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreditBanner;

const containerStyles = {
  width: '100%',
  padding: '34px',
  borderRadius: '12px',
  gap: '25px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px -2px #1018281A',
};

const headerStyles = {
  fontWeight: 600,
  fontSize: '30px',
  lineHeight: '36.31px',
  color: '#101828',
  letterSpacing: '-2%',
  textAlign: isMobile ? 'center' : 'left',
};

const textStyles = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16.94px',
  letterSpacing: '0%',
  coolor: '#101828',
};
