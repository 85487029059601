import React, { useEffect, useState } from 'react';
import AccountContainer from '../../../components/AccountContainer';
import subscriptionSideMenuItems from './subscriptionSideMenuItems';
import TripOverview from './TripOverview';
import SelectTripComponent from './SelectTripComponent';
import UploadDocument from './UploadDocument';
import { useJsonApi } from '../../../services/GlobalStore/GlobalStore';
import useAuthenticate from '../../../hooks/useAuthenticate';
import BuySubscription from './BuySubscription';
import Loading from '../../../components/Loading';
import CreditBanner from './CreditBanner';

const Premium = () => {
  const [addTrip, setAddTrip] = useState<boolean>(false);
  const [uploadBooking, setUploadBooking] = useState<boolean>(false);
  const { token } = useAuthenticate();

  const { data: flightsData, loading: subscriptionLoading } = useJsonApi(
    `/subscriptions`,
    {
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'subscriptionTrips',
          'subscriptionTrips.subscriptionFlights.airline',
          'subscriptionTrips.subscriptionFlights.departureAirport',
          'subscriptionTrips.subscriptionFlights.arrivalAirport',
        ].join(),
      },
      headers: { Authorization: `Bearer ${token}` },
    },
    []
  );

  return (
    <>
      {flightsData?.length && !subscriptionLoading ? (
        <AccountContainer menuItems={subscriptionSideMenuItems}>
          {!addTrip && !uploadBooking ? (
            <div className="flex flex-col" style={{ gap: '28px' }}>
              <TripOverview setAddTrip={setAddTrip} data={flightsData} />
              <CreditBanner data={flightsData} />
            </div>
          ) : addTrip && !uploadBooking ? (
            <SelectTripComponent
              setAddTrip={setAddTrip}
              setUploadBooking={setUploadBooking}
            />
          ) : (
            <UploadDocument
              setUploadBooking={setUploadBooking}
              setAddTrip={setAddTrip}
            />
          )}
        </AccountContainer>
      ) : !flightsData?.length && !subscriptionLoading ? (
        <BuySubscription />
      ) : (
        <div
          className="flex items-center align-center justify-center"
          style={{ height: '100vh' }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};

export default Premium;
