import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { CachePolicies } from 'use-http';

// Components
import Card from '../../components/Card';
import Input from '../../components/fields/Input';
import PhoneInput from '../../components/fields/PhoneInput';
import BirthdayPicker from '../../components/fields/BirthdayPicker/BirthdayPicker';
import StepTitle from '../../components/StepTitle';
import CountrySelect from '../../components/fields/CountrySelect';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
// import Banner from '../Account/Subscription/Banner';
// Hooks
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import { useHandleError } from '../../services/api';
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';
import { setAccount } from '../../services/GlobalStore/reducer';
import useAuthenticate from '../../hooks/useAuthenticate';

// Resource
import { updateAccount } from '../../services/resources/accounts';

// Translations
import personalInfoMessages from '../PersonalInfo/messages';
import messages from './messages';

// Config
import config from '../../config';
import accountSideMenuItems from './accountSideMenuItems';

// Utils
import {
  validatePhoneNumber,
  validateFirstname,
  validateLastname,
  validateDate,
} from '../../utils/yupValidators';

// Icons
import SearchIcon from '../../assets/icons/search-icon.inline.svg';
import AccountContainer from '../../components/AccountContainer';

const validationSchema = yup.object().shape({
  firstname: validateFirstname,
  lastname: validateLastname,
  phone: validatePhoneNumber.required(),
  birthday: validateDate.required(),
  email: yup
    .string()
    .email()
    .required(),
  streetname: yup.string().required(),
  postal: yup.string().required(),
  city: yup.string().required(),
  country: yup.string().required(),
});

const ClientAccount = () => {
  const { customerId } = useAuthenticate();
  const locale = config.locale;
  const country = config.country.toUpperCase();
  // const { token } = useAuthenticate();
  const { formatMessage } = useIntl();
  const [handleError] = useHandleError();
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);
  const [account, dispatch] = useGlobalState(`/accounts/me-${customerId}`);

  const {
    data: accounts,
    get: getAccount,
    loading: loadingAccount,
  } = useJsonApi(`/accounts/me`, {
    cachePolicy: CachePolicies.NO_CACHE,
    cacheLife: 0,
    queryParams: {
      u: '1',
      include: 'country',
    },
  });

  // const { data: subscriptionData, loading: subscriptionLoading } = useJsonApi(
  //   `/subscriptions`,
  //   {
  //     cachePolicy: 'no-cache',
  //     queryParams: {
  //       include: [
  //         'subscriptionTrips',
  //         'subscriptionTrips.subscriptionFlights.airline',
  //         'subscriptionTrips.subscriptionFlights.departureAirport',
  //         'subscriptionTrips.subscriptionFlights.arrivalAirport',
  //       ].join(),
  //     },
  //     headers: { Authorization: `Bearer ${token}` },
  //   },
  //   []
  // );

  async function handleSubmit(values, { setSubmitting }) {
    setErrors(false);
    setSuccess(false);
    if (account?.id) {
      await updateAccount(account?.id, {
        ...values,
        country: { id: values.country },
      })
        .then(() => {
          setSuccess(true);
          setSubmitting(false);
          getAccount();
        })
        .catch(error => {
          setSuccess(false);
          setSubmitting(false);
          handleError(error);
          setSubmitting(false);
        });
    }
  }

  useEffect(() => {
    if (!isEmpty(accounts)) {
      dispatch(setAccount(`/accounts/me-${accounts[0].id}`, accounts[0]));
      localStorage.setItem('customerId', accounts[0].id);
    }
    // eslint-disable-next-line
  }, [loadingAccount]);

  const initialValues = {
    ...account,
    country: account?.country_id || account?.country?.id,
  };

  if (account) {
    return (
      <AccountContainer menuItems={accountSideMenuItems}>
        {/* {!subscriptionData?.length && !subscriptionLoading && (
          <Banner
            name={account?.firstname}
            showPriceButton={true}
            style={{ marginBottom: '28px' }}
          />
        )} */}
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnMount
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Card
                style={{
                  paddingTop: 50,
                  paddingBottom: 50,
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
              >
                <StepTitle
                  style={{
                    marginBottom: 15,
                  }}
                >
                  {formatMessage(messages.personalInfo)}
                </StepTitle>
                <div className="fieldset sm:flex-row">
                  <Input
                    name="firstname"
                    type="text"
                    label={personalInfoMessages.firstName}
                    placeholder={personalInfoMessages.firstNamePlaceholder}
                  />
                  <Input
                    wrapperClassName="mt-25 sm:mt-0"
                    name="lastname"
                    type="text"
                    label={personalInfoMessages.lastName}
                    placeholder={personalInfoMessages.lastNamePlaceholder}
                  />
                </div>
                <div className="fieldset sm:flex-row mt-25">
                  <Input
                    name="email"
                    type="email"
                    label={messages.yourEmailAddress}
                    placeholder={messages.emailPlaceholder}
                  />
                  <PhoneInput
                    name="phone"
                    label={personalInfoMessages.phone}
                    placeholder={personalInfoMessages.phonePlaceholder}
                    portal={country}
                    locale={locale}
                  />
                </div>
                <div className="fieldset sm:flex-row mt-25 mb-25">
                  <BirthdayPicker
                    name="birthday"
                    autoComplete="off"
                    label={personalInfoMessages.birthDate}
                  />
                </div>
                <StepTitle
                  style={{
                    marginBottom: 15,
                  }}
                >
                  {formatMessage(messages.contactInfo)}
                </StepTitle>
                <div className="fieldset sm:flex-row">
                  <Input
                    name="streetname"
                    label={personalInfoMessages.address}
                    placeholder={personalInfoMessages.addressPlaceholder}
                  />
                  <Input
                    name="postal"
                    label={personalInfoMessages.postalCode}
                    placeholder={personalInfoMessages.postalCodePlaceholder}
                  />
                </div>
                <div className="fieldset sm:flex-row">
                  <Input
                    name="city"
                    label={personalInfoMessages.city}
                    placeholder={personalInfoMessages.cityPlaceholder}
                  />
                  <CountrySelect
                    name="country"
                    icon={<SearchIcon />}
                    hideIconOnValue={true}
                  />
                </div>
                {success ? (
                  <Alert level="success" className="w-full my-20">
                    {formatMessage(messages.messageDoneUpdatingProfile)}
                  </Alert>
                ) : errors?.length > 0 ? (
                  errors.map((error, index) => (
                    <Alert
                      // eslint-disable-next-line
                      key={`error-${index}`}
                      level="danger"
                      className="w-full my-20"
                    >
                      {error}
                    </Alert>
                  ))
                ) : null}
                <Button
                  theme="primary"
                  type="submit"
                  showIcon={false}
                  className="mt-40 sm:w-170"
                  disabled={!isValid || isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {formatMessage(messages.updateDetails)}
                </Button>
              </Card>
            </Form>
          )}
        </Formik>
      </AccountContainer>
    );
  }
  return null;
};

export default ClientAccount;
